.raffle-side-bar-container {
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;

  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-items: center;

  border-bottom: 1px solid #ffffff2c;

  .left-side {
    display: flex;
    gap: 10px;

    .heading-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        /* $100,000 */

        font-style: italic;
        font-weight: 700;
        font-size: 15px;

        /* identical to box height, or 15px */
        text-transform: uppercase;

        background: linear-gradient(181.17deg, #ffffff 39.55%, #e7e6de 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-wrap: nowrap;
        text-shadow: 0px 0px 18.0339px rgba(255, 255, 255, 0.5);
      }
      .sub-title {
        font-style: italic;
        font-weight: 700;
        font-size: 11px;
        line-height: 100%;
        padding: 2px 0;
        padding-right: 2px;
        text-wrap: nowrap;
        /* identical to box height, or 11px */
        text-transform: uppercase;

        background: linear-gradient(189.63deg, #ffffff 11.2%, #ffd706 97.93%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        text-shadow: 0px 0px 18.0339px rgba(222, 181, 52, 0.5);
      }
    }
  }

  .right-side {
    .days-left-container {
      background: linear-gradient(0deg, #ffe356 20%, #ffe35601 100%);
      border-radius: 4px;
      padding: 1px;
      .days-left-container-inner {
        border: 1px solid rgba(255, 227, 86, 0);
        background: #2e2f32;
        border-radius: inherit;
        padding: 3px;
      }
      span {
        // -webkit-text-stroke-width: 0.4687820076942444;
        // -webkit-text-stroke-color: #000;

        font-size: 11px;
        font-style: italic;
        font-weight: 700;
        line-height: 100%; /* 11px */
        letter-spacing: 0.165px;
        text-transform: uppercase;
        text-shadow:
          -1px -1px 0 #000,
          1px -1px 0 #000,
          -1px 1px 0 #000,
          1px 1px 0 #000,
          0.593px 0.593px 0 #ffe356; //

        &:first-child {
          padding-right: 2px;
        }
      }
    }
  }
}

.raffle-mini-side-bar-container {
  border-bottom: 1px solid #ffffff2c;
  //   padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon-container {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    transition: all 0.15s ease-in-out;

    .background-glow {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: radial-gradient(
        circle at center bottom,
        #ffd788 0%,
        rgba(0, 0, 255, 0) 100%
      );
      transition: opacity 0.15s ease-in-out;
      border-radius: inherit;
    }
    img {
      transition: all 0.15s ease-in-out;
      z-index: 1;
    }

    &:hover {
      border: 1px solid #ffd788;
      .background-glow {
        opacity: 1;
      }
      img {
        transform: scale(1.05);
        translate: 0 -2px;
      }
    }
  }
}

.raffle-drop-down-container {
  border: 1px solid #ffd788;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  height: 62px;
  background: #191f3b;
  border-radius: 6px;
  transition: background 0.15s ease;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    transition: opacity 0.15s ease;
  }

  &::before {
    background: linear-gradient(
      180deg,
      rgba(226, 179, 15, 0.25) 0%,
      rgba(252, 224, 77, 0.25) 100%
    );
    opacity: 1;
  }

  &::after {
    background: radial-gradient(
        100% 100% at 50% 0%,
        rgba(252, 224, 77, 0.25) 0%,
        rgba(252, 224, 77, 0) 100%
      ),
      linear-gradient(
        180deg,
        rgba(226, 179, 15, 0.25) 0%,
        rgba(252, 224, 77, 0.25) 100%
      );
    opacity: 0;
  }

  &:hover {
    &::before {
      opacity: 0;
    }

    &::after {
      opacity: 1;
    }

    .icon-container {
      animation: exitAnimation 0.15s ease forwards;
    }
  }

  .icon-container {
    animation: returnAnimation 0.15s ease forwards;
  }

  .left-side {
    display: flex;
    gap: 10px;
    z-index: 1;
    position: relative;

    .icon-container {
      margin-bottom: -25px;
    }
    .heading-container {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .title {
        padding-top: 2px;
        font-style: italic;
        font-weight: 700;
        font-size: 16px;

        /* identical to box height, or 15px */
        text-transform: uppercase;

        background: linear-gradient(181.17deg, #ffffff 39.55%, #e7e6de 99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-wrap: nowrap;
        text-shadow: 0px 0px 18.0339px rgba(255, 255, 255, 0.5);
      }

      .sub-title {
        font-style: italic;
        font-weight: 700;
        font-size: 13px;
        line-height: 100%;
        padding: 2px 0;
        padding-right: 2px;
        text-wrap: nowrap;
        /* identical to box height, or 11px */
        text-transform: uppercase;

        background: linear-gradient(189.63deg, #ffffff 11.2%, #ffd706 97.93%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        text-shadow: 0px 0px 18.0339px rgba(222, 181, 52, 0.5);
      }
    }
  }

  .right-side {
    display: flex;
    gap: 5px;
    z-index: 1;
    position: relative;
    span {
      font-size: 16px;
    }
  }
}

@keyframes exitAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-2px);
  }
}

@keyframes returnAnimation {
  0% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}
