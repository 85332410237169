.container {
  cursor: pointer;
  position: relative;
  display: flex;
  height: 48px;
  padding: 0 12px 0 10px;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-size: 12px;
  background:
    radial-gradient(
      115.08% 100% at 50% 101.11%,
      rgba(255, 179, 0, 0.1) 0%,
      rgba(255, 179, 0, 0.01) 100%
    ),
    linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    ),
    #191f3b;
  text-wrap: nowrap;
  border: 1px solid #534e53;

  svg {
    transition: all 0.2s ease;
  }

  .info {
    .label {
      font-size: 12.5px;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      background: linear-gradient(180deg, #fff0cc 0%, #ffb300 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .time {
      color: #e8e5ff;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      margin-top: 3px;
    }
  }

  @media screen and (max-width: 1280px) {
    width: 38px;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-header {
      display: none;
    }

    .info {
      display: none;
    }

    &:not(.dropdown-version) {
      border: 1px solid transparent;
      background: transparent;

      &::after {
        display: none;
      }

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  &.mobile {
    width: 100%;
    height: 67px;
    padding: 10px 12px 10px 16px;
    gap: 10px;
    border: 1px solid transparent;
    background:
      radial-gradient(
        115.08% 100% at 50% 101.11%,
        rgba(255, 179, 0, 0.3) 0%,
        rgba(255, 179, 0, 0.03) 100%
      ),
      linear-gradient(
        180deg,
        rgba(124, 131, 177, 0.15) 0%,
        rgba(94, 103, 158, 0.12) 100%
      );

    .info {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time {
        font-size: 13px;
      }
      .label {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 540px) {
      .svg-container-rakeback-boost {
        svg {
          width: 45px;
          height: 45px;
        }
      }
    }
  }

  &:not(.no-hover) {
    &:hover {
      border-radius: 6px;
      border: 1px solid #6e686e;
      background:
        radial-gradient(
          115.08% 100% at 50% 101.11%,
          rgba(255, 179, 0, 0.25) 0% rgba(255, 179, 0, 0.25) 100%
        ),
        linear-gradient(
          180deg,
          rgba(124, 131, 177, 0.15) 0%,
          rgba(94, 103, 158, 0.12) 100%
        );

      .tooltip {
        opacity: 1;
        visibility: visible;
        transform: translateX(-27%) translateY(0);
      }

      @media (max-width: 1280px) {
        &:not(.dropdown-version) {
          border: 1px solid transparent;

          .tooltip {
            opacity: 1;
            visibility: visible;
            transform: translateX(-74%) translateY(0);
          }

          svg {
            circle {
              fill: rgba(222, 168, 39, 0.1);
            }
          }

          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }

    // &:hover::after {
    //   background: linear-gradient(180deg, #fff0cc 0%, #ffb300 100%);
    // }
  }
}
.svg-container-rakeback-boost {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 530px) {
    display: flex !important;
  }
}

.tooltip {
  width: 100%;
  min-width: 250px;
  display: flex;
  align-items: center;
  padding: 12px;
  position: absolute;
  top: calc(100% + 10px);
  left: -49%;
  transform: translateX(-50%) translateY(0);
  border-radius: 6px;
  border: 1px solid rgba(124, 131, 177, 0.45);
  background:
    radial-gradient(
      237.62% 100% at 50% 101.11%,
      rgba(255, 179, 0, 0.1) 0%,
      rgba(255, 179, 0, 0.01) 100%
    ),
    linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    ),
    #191f3b;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  gap: 8px;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-color: #7c83b1 transparent transparent transparent;
  }

  .tooltip-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    text-wrap: auto;
    line-height: 130%;

    span:not(:first-child) {
      color: #e8e5ff;
    }

    .rake-label {
      color: transparent;
      background: linear-gradient(180deg, #fff0cc 0%, #ffb300 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
      font-size: 11px;
    }
  }

  &.inside-dropdown {
    top: -120%;
    left: 50%;
    transform: translateX(-51%) translateY(0);

    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px;
      border-style: solid;
      border-color: #7c83b1 transparent transparent transparent;
    }
  }
}

.tooltip-inside-dropdown {
  top: 72px;
  left: 27%;
  transform: translateX(-50%) translateY(0);

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-color: #7c83b1 transparent transparent transparent;
  }
}

.dropdown-version {
  background:
    radial-gradient(
      115.08% 100% at 50% 101.11%,
      rgba(255, 240, 204, 0.1) 0%,
      rgba(255, 179, 0, 0.1) 100%
    ),
    linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.15) 100%
    ) !important;
  border: 1px solid transparent;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    inset: -1px;
    padding: 1px;
    border-radius: 6px;
    background: linear-gradient(180deg, #fff0cc 0%, #ffb300 100%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
}

.info-hovered {
  transition: all 0.2s ease;
  background:
    radial-gradient(
      115.08% 100% at 50% 101.11%,
      rgba(255, 179, 0, 0.1) 0%,
      rgba(255, 179, 0, 0.01) 100%
    ),
    linear-gradient(
      180deg,
      rgba(124, 131, 177, 0.15) 0%,
      rgba(94, 103, 158, 0.12) 100%
    ),
    #191f3b;

  .tooltip {
    left: 50%;
    opacity: 1;
    visibility: visible;
  }
}
